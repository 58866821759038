<template>
  <GridItem class="gridYoutube" :has-hover-state="false" is-youtube>
    <div
      :class="{ 'gridYoutube__wrapper--visible': loaded }"
      class="gridYoutube__wrapper"
      style="--aspect-ratio: calc(16/9);"
    >
      <youtube
        :video-id="content.video_id"
        @playing="() => (playing = true)"
        @ready="() => (loaded = true)"
        ref="youtube"
      />
    </div>
  </GridItem>
</template>

<script>
import GridItemMixin from "@/components/mixins/grid-item";

export default {
  name: "GridYoutube",
  mixins: [GridItemMixin],
  data() {
    return {
      loaded: false,
      playing: false,
      muted: true,
      copied: false
    };
  }
};
</script>

<style lang="scss">
.gridYoutube {
  $self: &;
  position: relative;
  cursor: inherit;

  &__wrapper {
    height: 100%;
    transition: opacity 250ms linear;
    opacity: 0;

    &--visible {
      opacity: 1;
    }
  }

  video {
    cursor: pointer;
  }

  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    padding: 17px;
    opacity: 1;
    transition: opacity 250ms linear;
    color: white;
    font-size: 10px;
    text-transform: uppercase;
    display: flex;
    width: 100%;
    // justify-content: space-between;

    @media (hover: hover) {
      opacity: 0;

      #{$self}:hover & {
        opacity: 1;
      }
    }

    p,
    a,
    button {
      color: inherit;
    }

    p {
      margin: 0 21px;

      a,
      button {
        text-decoration: underline;
      }
    }

    button {
      background: none;
      border: unset;
      padding: 0;
      font-size: inherit;
    }
  }
}
</style>
