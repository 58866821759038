<template>
  <GridItem class="gridMainPlayer" :has-hover-state="false" is-youtube>
    <div
      :class="{ 'gridMainPlayer__wrapper--visible': loaded }"
      @transitionend.self="onTransitionEnd"
      class="gridMainPlayer__wrapper"
    >
      <!-- <div
        :class="{'gridMainPlayer__poster--visible': !playing }"
        class="gridMainPlayer__poster"
        @click="togglePlay"
      >
        <PlayIcon class="gridMainPlayer__posterPlay" />
        <img :src="content.media_poster" alt="" />
      </div>
      <youtube
        :video-id="content.video_id"
        :player-vars="options"
        @playing="() => (playing = true)"
        ref="youtube"
      /> -->
      <video
        ref="video"
        :src="content.media_url"
        :muted="muted"
        autoplay
        playsinline
        @click.self="togglePlaystate"
        loop
      />
      <footer class="gridMainPlayer__footer">
        <button @click="() => (muted = !muted)">
          SOUND {{ muted ? "OFF" : "ON" }}
        </button>
        <p v-show="userRole === 'press'">
          <button
            ref="embed"
            :data-clipboard-text="content.youtube_embed"
            href="#"
          >
            {{ copied ? "EMBED LINK COPIED" : "COPY EMBED LINK" }}
          </button>
        </p>
      </footer>
    </div>
  </GridItem>
</template>

<script>
import { mapState } from "vuex";
import Clipboard from "clipboard";
// import PlayIcon from "@/components/svg/play.svg";
import GridItemMixin from "@/components/mixins/grid-item";

export default {
  name: "gridMainPlayer",
  mixins: [GridItemMixin],
  // components: {
  //   PlayIcon
  // },
  data() {
    return {
      loaded: false,
      playing: false,
      muted: true,
      copied: false
    };
  },
  computed: {
    ...mapState({
      userRole: state => state.userRole
    })
  },
  mounted() {
    /* eslint-disable-next-line */
    const clip = new Clipboard(this.$refs.embed)
    clip.on("success", () => (this.copied = true));
    this.$refs.video.addEventListener("canplay", () => {
      setTimeout(() => {
        this.loaded = true;
      }, 300);
    });
  },
  methods: {
    onTransitionEnd() {
      this.$emit("youtube-ready");
    },
    togglePlaystate() {
      if (this.$refs.video.paused) this.$refs.video.play();
      else this.$refs.video.pause();
    }
  }
};
</script>

<style lang="scss">
.gridMainPlayer {
  $self: &;
  position: relative;
  cursor: inherit;

  &__poster {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 150ms linear;

    &--visible {
      opacity: 1;
    }

    &Play {
      position: inherit;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(2);
    }
  }

  &__wrapper {
    height: 100%;
    transition: opacity 250ms linear;
    opacity: 0;

    &--visible {
      opacity: 1;
    }
  }

  video {
    cursor: pointer;
  }

  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    padding: 17px;
    opacity: 1;
    transition: opacity 250ms linear;
    color: white;
    font-size: 10px;
    text-transform: uppercase;
    display: flex;
    width: 100%;
    // justify-content: space-between;

    @media (hover: hover) {
      opacity: 0;

      #{$self}:hover & {
        opacity: 1;
      }
    }

    p,
    a,
    button {
      color: inherit;
    }

    p {
      margin: 0 21px;

      a,
      button {
        text-decoration: underline;
      }
    }

    button {
      background: none;
      border: unset;
      padding: 0;
      font-size: inherit;
    }
  }
}
</style>
