var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"grid",staticClass:"contentGrid",class:{
    'contentGrid--tweening': _vm.isTweening,
  }},_vm._l((_vm.items),function(item){return _c(_vm.itemTag(item),{key:item.id,ref:"items",refInFor:true,tag:"component",staticClass:"contentGrid__item",class:{
      'contentGrid__item--portrait': _vm.isPortrait(item),
      'contentGrid__item--landscape': _vm.isLandscape(item),
      'contentGrid__item--video': _vm.isVideo(item),
      'contentGrid__item--mainPlayer': _vm.isMainPlayer(item),
      'contentGrid__item--vic': _vm.userRole === 'vic',
    },attrs:{"content":item,"has-hover-state":""},on:{"youtube-ready":_vm.onYoutubeReady}})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }