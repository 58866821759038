<template>
  <div class="formSignIn">
    <h2>WOMEN’s<br />SUMMER<br />21</h2>
    <form @submit="submit">
      <label for="password">
        <input
          id="password"
          type="password"
          name="password"
          v-model="password"
          autocomplete="new-password"
          placeholder="PASSWORD"
        />
      </label>
      <button type="submit">
        <Spinner v-show="submitting" />
        <Arrow v-show="!submitting" />
      </button>
    </form>
    <div class="formSignIn__error">
      <transition name="fade">
        <p v-show="error">PLEASE CHECK THAT YOU TYPED THE PASSWORD CORRECTLY</p>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import fetcher from '@/assets/scripts/fetcher'
import Arrow from '@/components/svg/arrow.svg'
import Spinner from '@/components/element/spinner'

export default {
  name: 'FormSignIn',
  components: {
    Spinner,
    Arrow,
  },
  data() {
    return {
      error: false,
      submitting: false,
      password: null,
    }
  },
  methods: {
    ...mapMutations({
      setTheme: 'setTheme',
      setUserLoggedIn: 'setUserLoggedIn',
      setUserRole: 'setUserRole',
    }),
    async submit(e) {
      e.preventDefault()
      this.submitting = true
      try {
        const signin = await fetcher.post('/auth/login', {
          password: this.password,
        })
        if (signin.data.access_token) {
          localStorage.setItem('Bearer', signin.data.access_token)
          fetcher.defaults.headers.common.Authorization = `Bearer ${signin.data.access_token}`
          localStorage.setItem('userRole', signin.data.role)
          this.setUserRole(signin.data.role)
          this.onSuccess()
        }
        this.submitting = false
      } catch (err) {
        this.submitting = false
        this.onError()
      }
    },
    onSuccess(slug = 'women-spring-summer-21') {
      this.error = false
      this.setUserLoggedIn(true)
      this.$router.push(`/book/${slug}`)
    },
    onError() {
      this.error = true
      this.setUserLoggedIn(false)
    },
  },
}
</script>

<style lang="scss">
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: white;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: white;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: white;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: white;
}

.formSignIn {
  width: 54.6875%;
  margin: auto;
  max-width: 168px;

  @media screen and (min-width: 768px) {
    max-width: 417px;
  }

  h2 {
    font-size: 35px;
    line-height: 28px;
    letter-spacing: -0.03em;
    margin-top: 0;
    margin-bottom: 20px;
    text-transform: uppercase;

    @media screen and (min-width: 768px) {
      font-size: 90px;
      line-height: 70px;
    }
  }

  form {
    align-items: stretch;
    border-bottom: 1px solid var(--white);
    display: flex;
    max-width: 560px;

    label {
      flex-grow: 1;
    }

    input,
    button {
      background-color: transparent;
      border: unset;
    }

    input {
      color: var(--white);
      padding: 4px 0;
      font-size: 10px;
      line-height: 12px;
      width: 100%;

      @media screen and (min-width: 768px) {
        font-size: 14px;
        line-height: 17px;
        padding: 7px 0;
      }

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px var(--black) inset !important;
        -webkit-text-fill-color: var(--white) !important;
      }
    }

    button {
      flex-shrink: 0;
      padding-right: 0;
      width: 13px;
      padding: 0;

      > svg {
        height: auto;
        width: 100%;
      }

      @media screen and (min-width: 768px) {
        width: 24px;
      }
    }
  }

  p {
    color: var(--white);
    font-size: 10px;
    line-height: 12px;
    letter-spacing: -0.01em;
    margin-bottom: 0;
  }

  &__error {
    margin-top: 6px;
    @media screen and (min-width: 768px) {
      font-size: 14px;
      margin-top: 10px;
    }
    height: 20px;
  }
}
</style>
