<template>
  <footer
    :class="{
      'pageFooter--dark': theme === 'dark',
      'pageFooter--light': theme === 'light'
    }"
    class="pageFooter"
  >
    <a
      href="https://www.ysl.com/en-en/legal-notices/name/terms-and-conditions-use.html"
      target="_blank"
      rel="noopener noreferrer"
      >Legal Notice</a
    >
  </footer>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "PageFooter",
  computed: {
    ...mapState({
      theme: state => state.theme
    })
  }
};
</script>

<style lang="scss">
.pageFooter {
  padding: 17px;

  @media screen and (min-width: 768px) {
    padding: 17px 30px;
  }

  &--dark {
    background-color: var(--black);
    color: var(--white);
  }

  &--light {
    background-color: var(--white);
    color: var(--black);
  }

  a {
    display: block;
    font-size: 10px;
    line-height: 12px;
    text-decoration: unset;
    text-transform: uppercase;
  }
}
</style>
