<template>
  <nav :class="{ 'menu--visible': isMenuOpen }" class="menu">
    <button
      v-show="$route.name !== 'Index'"
      @click="
        () => {
          setMenuOpen(true)
          setPopinOpen(false)
        }
      "
      :class="{ 'menu__button--visible': !isMenuOpen }"
      class="menu__button"
    >
      menu
    </button>
    <ul>
      <li>
        <button @click="openYoutubePlayer">Video</button>
      </li>
      <li
        v-for="group in userRole === 'vic'
          ? groups.filter((g) => g.group_name !== 'VIDEOS CUT DOWN')
          : groups"
        :key="group.group_id"
      >
        <button @click="() => onClick(group)">{{ group.group_name }}</button>
      </li>
      <li v-if="userRole === 'press'">
        <a
          href="/documents/SAINT_LAURENT_SUMMER21_PRESS_RELEASE_SHOW.pdf"
          download
          >Press Release</a
        >
      </li>
      <li v-if="userRole === 'press'">
        <a
          href="https://womenss21.neuviemepage.com/content/DOWNLOADS/ALL.zip"
          download
          >Download All</a
        >
      </li>
    </ul>
    <button @click="() => setMenuOpen(false)" class="menu__backButton">
      back
    </button>
  </nav>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'Menu',
  computed: {
    ...mapState({
      isMenuOpen: (state) => state.isMenuOpen,
      groups: (state) => state.content.groups || [],
      userRole: (state) => state.userRole,
    }),
  },
  methods: {
    ...mapMutations({
      setMenuOpen: 'setMenuOpen',
      setPopinOpen: 'setPopinOpen',
      setPopinSource: 'setPopinSource',
      setPopinContent: 'setPopinContent',
    }),
    onClick(group) {
      this.setPopinSource('group')
      this.setPopinContent(group)
      this.$nextTick(() => {
        this.setPopinOpen(true)
        this.setMenuOpen(false)
      })
    },
    openYoutubePlayer() {
      this.setPopinSource('embed')
      this.setPopinContent({ group_name: 'VIDEO' })
      this.$nextTick(() => {
        this.setPopinOpen(true)
        this.setMenuOpen(false)
      })
    },
  },
}
</script>

<style lang="scss">
.menu {
  position: fixed;
  background-color: var(--white);
  color: var(--black);
  padding: 17px;
  top: 47px;
  left: 0;
  height: calc(100vh - 47px);
  z-index: 3;
  width: 100%;
  transition: transform 0.2s ease-in-out;
  transform: translateX(100%);

  @media screen and (min-width: 768px) {
    --gutter: calc(clamp(4px, 0.3vw, 10px) * 4);
    color: var(--white);
    top: 0;
    padding-top: 74px;
    padding-left: 30px;
    transform: translateX(-100%);
    background-color: var(--alpha-black);
    height: 100vh;
    width: calc((100% - var(--gutter)) / 5);
  }

  &--visible {
    transform: translateX(0);
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li,
  button {
    font-size: 10px;
    line-height: 26px;
    text-transform: uppercase;

    @media screen and (min-width: 768px) {
      line-height: 12px;
    }
  }

  button {
    background-color: transparent;
    border: none;
    padding: 0;
    color: inherit;
    &:not(.menu__button) {
      display: block;
    }
  }

  &__button {
    &,
    &--visible {
      display: none;
    }

    @media screen and (min-width: 768px) {
      display: block;
      pointer-events: none;
      position: absolute;
      top: 74px;
      right: -17px;
      transform: translateX(100%);
      transition: opacity 16ms steps(1);
      opacity: 0;

      @media screen and (min-width: 768px) {
        right: -30px;
      }

      &--visible {
        transition-delay: 500ms;
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &__backButton {
    @media screen and (max-width: 767px) {
      display: none !important;
    }
    @media screen and (min-width: 768px) {
      margin-top: 12px;
    }
  }

  a {
    text-decoration: none;
  }
}
</style>
