<template>
  <section class="notFoundPage">
    <main class="notFoundPage__main">
      <p>
        SORRY, WE COULDN’T FIND THE PAGE YOU ARE LOOKING FOR.<br />
        IT MAY HAVE BEEN REMOVED, CHANGED OR IS TEMPORARILY UNAVAILABLE.
      </p>
      <p>PLEASE RETURN TO OUR HOME PAGE TO CONTINUE BROWSING OUR SITE.</p>
      <p><router-link to="/">BACK TO HOME</router-link></p>
    </main>
    <PageFooter />
  </section>
</template>

<script>
import { mapMutations } from "vuex";
import PageFooter from "@/components/component/page-footer";

export default {
  name: "NotFound",
  components: {
    PageFooter
  },
  created() {
    this.setTheme("light");
  },
  methods: {
    ...mapMutations({
      setTheme: "setTheme"
    })
  }
};
</script>

<style lang="scss">
.notFoundPage__main {
  padding: 17px;

  @media screen and (min-width: 768px) {
    padding: 0 30px 30px 30px;
  }

  p {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: -0.015em;
  }
}
</style>
