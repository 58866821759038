<template>
  <div
    ref="grid"
    :class="{
      'contentGrid--tweening': isTweening,
    }"
    class="contentGrid"
  >
    <component
      v-for="item in items"
      ref="items"
      :key="item.id"
      :is="itemTag(item)"
      :content="item"
      has-hover-state
      :class="{
        'contentGrid__item--portrait': isPortrait(item),
        'contentGrid__item--landscape': isLandscape(item),
        'contentGrid__item--video': isVideo(item),
        'contentGrid__item--mainPlayer': isMainPlayer(item),
        'contentGrid__item--vic': userRole === 'vic',
      }"
      @youtube-ready="onYoutubeReady"
      class="contentGrid__item"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import gsap from 'gsap'
import GridImage from '@/components/component/grid-image'
import GridVideo from '@/components/component/grid-video'
import GridMainPlayer from '@/components/component/grid-main-player'

export default {
  name: 'ContentGrid',
  components: {
    GridImage,
    GridVideo,
    GridMainPlayer,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      animationItems: [],
      isTweening: true,
    }
  },
  computed: {
    ...mapState({
      userRole: (state) => state.userRole,
    }),
  },
  mounted() {
    this.animationItems = this.$refs.items
      .sort((a, b) => {
        const { left: aLeft, top: aTop } = a.$el.getBoundingClientRect()
        const { left: bLeft, top: bTop } = b.$el.getBoundingClientRect()
        return aTop === bTop ? aLeft - bLeft : aTop - bTop
      })
      .filter((item) => item.$vnode.componentOptions.tag !== 'GridMainPlayer')
      .reduce((acc, item) => {
        return [...acc, ...item.$children.map((child) => child.$refs.inner)]
      }, [])
    gsap.set(this.animationItems, { opacity: 0 })
  },
  methods: {
    itemTag({ media_type }) {
      switch (media_type) {
        case 'youtube':
          return 'GridMainPlayer'
        case 'video':
          return 'GridVideo'
        case 'image':
          return 'GridImage'
        default:
          return null
      }
    },
    isMainPlayer({ media_type }) {
      return media_type === 'youtube'
    },
    isPortrait({ media_type, media_ratio }) {
      return (
        media_ratio[0] < media_ratio[1] && !this.isMainPlayer({ media_type })
      )
    },
    isLandscape({ media_type, media_ratio }) {
      return (
        media_ratio[0] > media_ratio[1] && !this.isMainPlayer({ media_type })
      )
    },
    isVideo({ media_type }) {
      return media_type === 'video'
    },
    onYoutubeReady() {
      gsap.fromTo(
        this.animationItems,
        {
          opacity: 0,
        },
        {
          duration: 0.25,
          opacity: 1,
          ease: 'power1.inOut',
          stagger: {
            amount: 1.5,
          },
          onComplete: () => {
            this.isTweening = false
          },
        }
      )
    },
  },
}
</script>

<style lang="scss">
.contentGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 8.65vw;
  grid-row-gap: 4px;
  row-gap: 4px;
  grid-column-gap: 4px;
  column-gap: 4px;
  // grid-auto-flow: column;

  &--tweening {
    pointer-events: none;
  }

  @media screen and (min-width: 400px) {
    grid-auto-rows: 8.95vw;
  }

  @media screen and (min-width: 640px) {
    grid-auto-rows: 9.35vw;
  }

  @media screen and (min-width: 768px) {
    --gap: clamp(4px, 0.3vw, 10px);
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: 4.8vw;
    grid-row-gap: 10px;
    row-gap: var(--gap, 10px);
    grid-column-gap: 10px;
    column-gap: var(--gap, 10px);
    grid-auto-flow: unset;
  }

  @media screen and (min-width: 960px) {
    grid-auto-rows: 5vw;
  }

  @media screen and (min-width: 1440px) {
    grid-auto-rows: 5.2vw;
  }

  @media screen and (min-width: 1680px) {
    grid-auto-rows: 5.35vw;
  }

  &__item {
    cursor: pointer;
    @media screen and (max-width: 767px) {
      // &:nth-child(3n) {
      //   grid-column: 3 / span 1;
      // }

      // &:nth-child(3n - 1) {
      //   grid-column: 2 / span 1;
      // }

      &--mainPlayer {
        grid-column: 1 / span 3;
        grid-row: 6 / span 6;
      }

      &--landscape {
        grid-row: span 2;
      }

      &--portrait {
        grid-row: span 5;
      }

      // &--portrait {
      //   grid-row: 1 / span 5;
      //   grid-column: 1 / span 1;
      // }

      // &--portrait ~ &--portrait {
      //   grid-row: 1 / span 5;
      //   grid-column: 2 / span 1;
      // }

      // &--portrait ~ &--portrait ~ &--portrait {
      //   grid-row: 1 / span 5;
      //   grid-column: 3 / span 1;
      // }

      // &--portrait ~ &--portrait ~ &--portrait ~ &--portrait {
      //   grid-row: span 5;
      //   grid-column: auto;
      // }
    }

    @media screen and (min-width: 768px) {
      // $self: &;
      // &--vic {
      //   @for $i from 4 through 0 {
      //     &:not(#{$self}--mainPlayer):nth-child(5n - #{$i}) {
      //       grid-column: #{5 - $i} / span 1;
      //     }
      //   }
      // }
      &--mainPlayer {
        grid-column: 2 / span 3;
        grid-row: 3 / span 6;
      }

      &--portrait {
        grid-row: span 5;
      }

      &--landscape {
        grid-row: span 2;
      }

      & ~ &--landscape {
        grid-row: 1 / span 2;
        grid-column: 2 / span 1;
      }

      & ~ &--landscape ~ &--landscape {
        grid-row: 1 / span 2;
        grid-column: 3 / span 1;
      }

      & ~ &--landscape ~ &--landscape ~ &--landscape {
        grid-row: 1 / span 2;
        grid-column: 4 / span 1;
      }

      & ~ &--landscape ~ &--landscape ~ &--landscape ~ &--landscape {
        grid-row: span 2;
        grid-column: auto;
      }
    }
  }
}
</style>
