var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{directives:[{name:"show",rawName:"v-show",value:(_vm.showHeader),expression:"showHeader"}],staticClass:"shellHeader",class:{
    'shellHeader--dark': _vm.theme === 'dark',
    'shellHeader--light': _vm.theme === 'light',
    'shellHeader--book': _vm.$route.name === 'Book'
  }},[_c('a',{staticClass:"shellHeader__logo",attrs:{"href":"/"},on:{"click":function($event){$event.preventDefault();return _vm.scrollToTop($event)}}},[_c('h1',[_vm._v("Saint Laurent")]),_c('LogoSvg')],1),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name !== 'Index' && _vm.$route.name !== 'NotFound'),expression:"$route.name !== 'Index' && $route.name !== 'NotFound'"}],staticClass:"shellHeader__menuButton",class:{
      'shellHeader__menuButton--close': _vm.isMenuOpen || _vm.isPopinOpen,
      'shellHeader__menuButton--popin': !_vm.isPopinOpen
    },on:{"click":_vm.toggleMenu}},[_vm._m(0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"shellHeader__menuButtonIcon"},[_c('span')])}]

export { render, staticRenderFns }