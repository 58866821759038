var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contentPopin",on:{"click":function () { return _vm.setPopinOpen(false); }}},[_c('div',{ref:"inner",staticClass:"contentPopin__inner",on:{"click":function($event){$event.stopPropagation();return _vm.onClickStop($event)}}},[_c('header',{staticClass:"contentPopin__header"},[_c('h2',[_vm._v(_vm._s(_vm.title))]),(_vm.source !== 'embed')?[(_vm.source === 'grid' && _vm.userRole === 'press')?_c('p',[_c('a',{attrs:{"href":_vm.popinContent.media_url.replace('THUMBNAILS', 'DOWNLOADS'),"download":""}},[_vm._v(" Download "+_vm._s(_vm.isGridImage ? 'image' : 'video')+" ")])]):_vm._e(),(
            typeof _vm.popinContent.group_id === 'number' && _vm.userRole === 'press'
          )?_c('p',[_c('a',{attrs:{"href":_vm.bundle,"download":""}},[_vm._v("Download full package")])]):_vm._e()]:[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.userRole === 'press'),expression:"userRole === 'press'"}]},[_c('button',{ref:"embed",attrs:{"data-clipboard-text":_vm.content.video.youtube_embed}},[_vm._v(" "+_vm._s(_vm.copied ? 'EMBED LINK COPIED' : 'COPY EMBED LINK')+" ")])])]],2),_c('section',{ref:"content",staticClass:"contentPopin__content",class:{
        'contentPopin__content--grid': _vm.isGrid,
        'contentPopin__content--aspect': !_vm.isGrid,
        'contentPopin__content--gridImage': _vm.isGrid && _vm.isGridImage,
        'contentPopin__content--gridVideo': _vm.isGrid && _vm.isGridVideo,
      },style:(!_vm.isGrid && !_vm.isMobile && '--aspect-ratio:var(--ratio);')},_vm._l((_vm.data),function(item){return _c('a',{key:item.id,staticClass:"contentPopin__item",class:{
          'contentPopin__item--portrait': _vm.isPortrait(item),
          'contentPopin__item--landscape': _vm.isLandscape(item),
          'contentPopin__item--videos':
            item.media_type === 'video' && _vm.data.length > 1,
          'contentPopin__item--contain': !_vm.isGrid,
        },style:(_vm.userRole === 'vic' && _vm.isGrid && 'cursor:pointer!important;'),attrs:{"href":_vm.isGrid &&
          _vm.popinContent.group_name !== 'VIDEOS CUT DOWN' &&
          _vm.userRole === 'press'
            ? item.media_url.replace('THUMBNAILS', 'DOWNLOADS')
            : undefined,"download":""}},[_c(_vm.itemTag(item.media_type),{ref:"items",refInFor:true,tag:"component",style:(_vm.isGrid && ("--aspect-ratio:calc(" + (item.media_ratio.join(' / ')) + ");")),attrs:{"content":item,"autoplay":item.media_type === 'video' && _vm.data.length === 1,"in-grid":_vm.isGrid,"mouse-play":"","can-open-popin":_vm.userRole === 'vic'}})],1)}),0),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCredits),expression:"showCredits"}],staticClass:"contentPopin__credit"},[_vm._v(" "+_vm._s(_vm.data.length > 1 || _vm.popinContent.group_name === 'VIDEO' ? 'A selection of jewelry featured in the collection are designed by Claude Lalanne' : 'Jewelry by Claude Lalanne')+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }