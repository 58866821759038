import Vue from 'vue'
import Vuex from 'vuex'
import fetcher from '@/assets/scripts/fetcher'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    theme: 'light',
    isMenuOpen: false,
    isUserLoggedIn: false,
    userRole: '',
    isPopinOpen: false,
    content: {},
    popinContent: {},
    popinSource: '',
    showHeader: false,
  },
  mutations: {
    setUserRole(state, payload) {
      state.userRole = payload

      document.body.classList.add(state.userRole)
    },
    setShowHeader(state, payload) {
      state.showHeader = payload
    },
    setTheme(state, payload) {
      state.theme = payload
    },
    setMenuOpen(state, payload) {
      state.isMenuOpen = payload
    },
    setUserLoggedIn(state, payload) {
      state.isUserLoggedIn = payload
    },
    setContent(state, payload) {
      state.content = payload
    },
    setPopinOpen(state, payload) {
      state.isPopinOpen = payload
    },
    setPopinContent(state, payload) {
      state.popinContent = payload
    },
    setPopinSource(state, payload) {
      state.popinSource = payload
    },
  },
  actions: {
    async fetchContent({ commit }) {
      const content = await fetcher.get(`/assets`)
      commit('setContent', content.data)
    },
  },
  modules: {},
})
