<template>
  <div class="contentPopin" @click="() => setPopinOpen(false)">
    <div class="contentPopin__inner" ref="inner" @click.stop="onClickStop">
      <header class="contentPopin__header">
        <h2>{{ title }}</h2>
        <template v-if="source !== 'embed'">
          <p v-if="source === 'grid' && userRole === 'press'">
            <a
              :href="popinContent.media_url.replace('THUMBNAILS', 'DOWNLOADS')"
              download
            >
              Download {{ isGridImage ? 'image' : 'video' }}
            </a>
          </p>
          <p
            v-if="
              typeof popinContent.group_id === 'number' && userRole === 'press'
            "
          >
            <a :href="bundle" download>Download full package</a>
          </p>
        </template>
        <template v-else>
          <p v-show="userRole === 'press'">
            <button
              ref="embed"
              :data-clipboard-text="content.video.youtube_embed"
            >
              {{ copied ? 'EMBED LINK COPIED' : 'COPY EMBED LINK' }}
            </button>
          </p>
        </template>
      </header>
      <section
        ref="content"
        :class="{
          'contentPopin__content--grid': isGrid,
          'contentPopin__content--aspect': !isGrid,
          'contentPopin__content--gridImage': isGrid && isGridImage,
          'contentPopin__content--gridVideo': isGrid && isGridVideo,
        }"
        :style="!isGrid && !isMobile && '--aspect-ratio:var(--ratio);'"
        class="contentPopin__content"
      >
        <a
          :href="
            isGrid &&
            popinContent.group_name !== 'VIDEOS CUT DOWN' &&
            userRole === 'press'
              ? item.media_url.replace('THUMBNAILS', 'DOWNLOADS')
              : undefined
          "
          v-for="item in data"
          :key="item.id"
          download
          :class="{
            'contentPopin__item--portrait': isPortrait(item),
            'contentPopin__item--landscape': isLandscape(item),
            'contentPopin__item--videos':
              item.media_type === 'video' && data.length > 1,
            'contentPopin__item--contain': !isGrid,
          }"
          class="contentPopin__item"
          :style="userRole === 'vic' && isGrid && 'cursor:pointer!important;'"
        >
          <component
            ref="items"
            :is="itemTag(item.media_type)"
            :content="item"
            :autoplay="item.media_type === 'video' && data.length === 1"
            :in-grid="isGrid"
            mouse-play
            :can-open-popin="userRole === 'vic'"
            :style="
              isGrid && `--aspect-ratio:calc(${item.media_ratio.join(' / ')});`
            "
          />
        </a>
      </section>
      <p v-show="showCredits" class="contentPopin__credit">
        {{
          data.length > 1 || popinContent.group_name === 'VIDEO'
            ? 'A selection of jewelry featured in the collection are designed by Claude Lalanne'
            : 'Jewelry by Claude Lalanne'
        }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import GridImage from '@/components/component/grid-image'
import GridVideo from '@/components/component/grid-video'
import GridYoutube from '@/components/component/grid-youtube'
import Clipboard from 'clipboard'

export default {
  name: 'ContentPopin',
  components: {
    GridImage,
    GridVideo,
    GridYoutube,
  },
  data() {
    return {
      copied: false,
    }
  },
  watch: {
    popinContent(newValue, oldValue) {
      if (newValue !== oldValue) this.$el.scrollTo(0, 0)
    },
  },
  computed: {
    ...mapState({
      source: (state) => state.popinSource,
      popinContent: (state) => state.popinContent,
      content: (state) => state.content,
      userRole: (state) => state.userRole,
    }),
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    },
    showCredits() {
      return (
        this.popinContent.group_name === 'LOOKS' ||
        this.popinContent.group_name === 'DETAILS LOOKS' ||
        this.popinContent.group_name === 'STILLS VIDEO' ||
        this.popinContent.group_name === 'VIDEO' ||
        this.popinContent.group_name === 'VIDEOS CUT DOWN' ||
        (this.data.length === 1 &&
          this.data[0].media_thumbnail &&
          this.data[0].media_thumbnail.includes(
            'SAINT LAURENT_WOMEN_SUMMER21_FITTINGS_26_HR'
          )) ||
        (this.data.length === 1 &&
          this.data[0].media_thumbnail &&
          this.data[0].media_thumbnail.includes(
            'SAINT LAURENT_SUMMER21_DETAIL_16_HR'
          )) ||
        (this.data.length === 1 &&
          this.data[0].media_thumbnail &&
          this.data[0].media_thumbnail.includes(
            'SAINT LAURENT_SUMMER21_DETAIL_07_HR'
          )) ||
        (this.data.length === 1 &&
          this.data[0].media_thumbnail &&
          this.data[0].media_thumbnail.includes(
            'SAINT LAURENT_WOMEN_SUMMER21_FITTINGS_36_HR'
          )) ||
        (this.data.length === 1 &&
          this.data[0].media_thumbnail &&
          this.data[0].media_thumbnail.includes(
            'SAINT LAURENT_SUMMER21_DETAIL_24_HR'
          )) ||
        (this.data.length === 1 &&
          this.data[0].media_thumbnail &&
          this.data[0].media_thumbnail.includes(
            'SAINT LAURENT_WOMEN_SUMMER21_FITTINGS_08_HR'
          )) ||
        (this.data.length === 1 &&
          this.data[0].media_thumbnail &&
          this.data[0].media_thumbnail.includes(
            'SAINT LAURENT_SUMMER21_VIDEO_STILL_05_HR'
          ))
      )
    },
    isGrid() {
      return this.data.length > 1
    },
    isGridImage() {
      return (
        this.data.every(({ media_type }) => media_type === 'image') &&
        this.popinContent.group_name !== 'STILLS VIDEO'
      )
    },
    isGridVideo() {
      return (
        this.data.every(({ media_type }) => media_type === 'video') ||
        this.popinContent.group_name === 'STILLS VIDEO'
      )
    },
    title() {
      if ('group_name' in this.popinContent) return this.popinContent.group_name
      const group =
        this.content.groups.find(
          ({ group_id }) => this.popinContent.group_id === group_id
        ) || {}
      return group.group_name
    },
    bundle() {
      return (
        this.popinContent.package ||
        this.content.groups.find(
          ({ group_id }) => this.popinContent.group_id === group_id
        ).package
      )
    },
    data() {
      if (this.source === 'embed') return [{ ...this.content.video }]
      const data = this.popinContent.group_name
        ? this.content.medias.filter(
            ({ group_id }) => this.popinContent.group_id === group_id
          )
        : [this.popinContent]

      if (this.popinContent.group_name === 'VIDEOS CUT DOWN') {
        data.splice(1, 0, data.pop())
      }

      return data
    },
  },
  mounted() {
    if (this.source === 'embed') {
      const clip = new Clipboard(this.$refs.embed)
      clip.on('success', () => (this.copied = true))
    }
    window.addEventListener('keyup', this.close, false)
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.close, false)
  },
  methods: {
    ...mapMutations({
      setPopinOpen: 'setPopinOpen',
    }),
    itemTag(media_type) {
      switch (media_type) {
        case 'youtube':
          return 'GridYoutube'
        case 'video':
          return 'GridVideo'
        case 'image':
          return 'GridImage'
        default:
          return null
      }
    },
    isPortrait({ media_ratio }) {
      return media_ratio[0] < media_ratio[1]
    },
    isLandscape({ media_ratio }) {
      return media_ratio[0] > media_ratio[1]
    },
    onClickStop(e) {
      if (
        e.target instanceof HTMLVideoElement ||
        e.target instanceof HTMLImageElement ||
        e.target.tagName === 'A' ||
        e.target.tagName === 'BUTTON'
      )
        return
      this.setPopinOpen(false)
    },
    close(event) {
      if (event.keyCode === 27) {
        this.setPopinOpen(false)
      }
    },
  },
}
</script>

<style lang="scss">
.contentPopin {
  color: var(--white);
  position: fixed;
  z-index: 2;
  width: 100vw;
  height: calc(100vh - 50px);
  background-color: var(--alpha-black);
  padding: 17px;
  overflow-y: auto;
  overflow-x: hidden;
  top: 50px;
  height: calc(
    100vh - 50px
  ); /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100 - 50px);

  @media screen and (min-width: 768px) {
    top: 0;
    padding: 0 0;
    height: 100vh;
  }

  &__inner {
    min-height: 100%;

    @media screen and (min-width: 768px) {
      --gap: clamp(4px, 0.3vw, 10px);
      width: 100vw;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: repeat(2, 4.8vw) auto;
      grid-column-gap: 10px;
      column-gap: var(--gap);
      grid-row-gap: 10px;
      row-gap: var(--gap);
    }

    @media screen and (min-width: 960px) {
      grid-template-rows: repeat(2, 5vw) auto;
    }

    @media screen and (min-width: 1440px) {
      grid-template-rows: repeat(2, 5.2vw) auto;
    }

    @media screen and (min-width: 1680px) {
      grid-template-rows: repeat(2, 5.35vw) auto;
    }
  }

  &__header {
    align-self: end;
    font-size: 10px;
    line-height: 12px;
    font-weight: normal;
    text-transform: uppercase;
    padding-bottom: 20px;

    @media screen and (min-width: 768px) {
      grid-row: 1 / span 2;
      grid-column: 2 / span 3;
    }

    > * {
      font: inherit;
      line-height: inherit;
      margin: 0;
    }

    button {
      color: white;
      font: inherit;
      background: transparent;
      border: unset;
      padding: 0;
      text-decoration: underline;
    }
  }

  &__content {
    grid-row-start: 3;
    align-self: start;
    padding-bottom: 50px;
    grid-column: span 3;
    @media screen and (min-width: 768px) {
      grid-column: 2 / span 3;
    }

    &--grid {
      display: grid;
      grid-column-gap: 4px;
      column-gap: 4px;
      grid-row-gap: 4px;
      row-gap: 4px;

      &Image {
        grid-template-columns: repeat(3, 1fr);
      }

      &Video {
        grid-template-columns: repeat(3, 1fr);
      }

      @media screen and (min-width: 768px) {
        --gap: clamp(4px, 0.3vw, 10px);
        grid-column-gap: 10px;
        column-gap: var(--gap, 10px);
        grid-row-gap: 10px;
        row-gap: var(--gap, 10px);

        &Image {
          grid-template-columns: repeat(6, 1fr);
        }

        &Video {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }

    @media screen and (min-width: 768px) {
      &:not(&--grid) {
        padding-bottom: 0;
        --ratio: calc(1148 / 668);
      }
    }
  }

  &__item {
    $self: &;
    &:not(&--contain) {
      height: 100%;
    }

    > :first-child {
      height: 100%;
    }

    &--contain {
      @media screen and (min-width: 768px) {
        --fit: contain;
        height: 100%;

        img,
        video {
          height: 100%;
          width: auto;
          margin: auto;
        }
      }
    }

    &--landscape {
      grid-column: span 1;
    }
  }

  &__credit {
    font-size: 10px;
    font-family: inherit;
    text-transform: uppercase;
    grid-row-start: 4;
    grid-column: 1 / span 2;
    align-self: end;
    @media screen and (min-width: 768px) {
      padding-left: 30px;
      padding-bottom: 30px;
      padding-top: 15px;
      margin: 0;
    }
  }
}
</style>
