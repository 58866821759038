<template>
  <section class="bookPage">
    <main class="bookPage__main">
      <div class="asscroll-container">
        <div data-scroll>
          <ContentGrid :items="mergedContent" />
          <PageFooter />
        </div>
      </div>
      <transition name="fade" appear>
        <ContentPopin v-if="isPopinOpen" />
      </transition>
    </main>
  </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
// import LocomotiveScroll from 'locomotive-scroll';

import store from '@/store'
import ContentGrid from '@/components/component/content-grid'
import ContentPopin from '@/components/component/content-popin'
import PageFooter from '@/components/component/page-footer'
import ASScroll from '@ashthornton/asscroll'
import Cookies from 'js-cookie'

export default {
  components: {
    ContentGrid,
    ContentPopin,
    PageFooter,
  },
  async beforeRouteEnter(to, from, next) {
    try {
      await store.dispatch('fetchContent')
      next()
    } catch (err) {
      next('/')
    }
  },
  data() {
    return {
      scroll: null,
    }
  },
  computed: {
    ...mapState({
      content: (state) => state.content,
      isPopinOpen: (state) => state.isPopinOpen,
      isMenuOpen: (state) => state.isMenuOpen,
    }),
    mergedContent() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
        ? [...this.content.book_grid_mobile, this.content.video]
        : [...this.content.book_grid, this.content.video]
    },
  },
  watch: {
    isPopinOpen(value) {
      // this.toggleScroll(value || this.isMenuOpen);

      this.toggleScroll(value)
    },
    isMenuOpen(value) {
      if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
        // this.toggleScroll(value || this.isPopinOpen)

        this.toggleScroll(!value)
      }
    },
  },
  created() {
    if (!Cookies.get('ga')) {
      const banner = document.getElementById('onetrust-banner-sdk')
      const accept = document.getElementById('onetrust-accept-btn-handler')
      const reject = document.getElementById('onetrust-reject-all-handler')
      reject.style.display = 'block !important'
      banner.style.display = 'unset'
      accept.addEventListener(
        'click',
        () => {
          // this.$ga.enable();
          banner.style.transform = 'translateY(100%)'
          Cookies.set('ga', '1')
        },
        false
      )
    } else {
      // this.$ga.enable();
    }

    this.setTheme('light')
    this.setShowHeader(true)
    this.$bus.$on('scrollToTop', () => {
      if (this.scroll instanceof ASScroll) {
        this.scroll.scrollTo(0)
      }
    })
  },
  mounted() {
    this.$nextTick(() => {
      if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) return

      this.scroll = new ASScroll({
        ease: 0.1,
      })
      this.scroll.enable()
    })
  },
  methods: {
    ...mapMutations({
      setTheme: 'setTheme',
      setShowHeader: 'setShowHeader',
    }),
    toggleScroll(disable = false) {
      if (disable) {
        if (this.scroll) this.scroll.disable()
        else document.body.style.overflow = 'hidden'
      } else {
        if (this.scroll) this.scroll.enable()
        else document.body.style.overflow = 'auto'
      }
    },
  },
}
</script>

<style lang="scss">
.bookPage {
  // @media screen and (min-width: 768px) {
  //   margin-top: -60px;
  // }

  &,
  &__main {
    display: block;
  }

  &__main {
    background-color: var(--white);
    color: var(--black);
  }
}
</style>
